// Look for .hamburger
var bodyElement = document.getElementsByTagName("BODY")[0];
var hamburger = document.getElementsByClassName("hamburger");
var eqHeader = document.querySelector("#eq-header");
var dropdownMenuButton = document.getElementsByClassName("dropdownMenuButton");
var dropdownMenu = document.getElementsByClassName("dropdown-menu");
var eqLogo = document.getElementById("eq-logo");
let dropdownMenuMainDiv = document.getElementById("dropdownMenuMobDiv");

// Dropdown menu - extra menus
let HelpLink = document.getElementById("helpLink");
let HelpLinkBack = document.getElementById("HelpLinkBack");
let HelpLinksDiv = document.getElementById("helpLinks");

let myAccountLink = document.getElementById("myAccount");
let myAccountLinkBack = document.getElementById("myAccountLinkBack");
let myAccountLinksDiv = document.getElementById("myAccountLinks");

let myInvestmentLink = document.getElementById("myInvestment");
let myInvestmentLinkBack = document.getElementById("myInvestmentLinkBack");
let myInvestmentLinksDiv = document.getElementById("myInvestmentLinks");

// --- Trustee Submenue ---

let companiesIdLink = document.getElementById("companiesId");
let companiesLinkBack = document.getElementById("companiesLinkBack");
let companiesLinksDiv = document.getElementById("companiesLinks");

let contributionsIdLink = document.getElementById("contributionsId");
let contributionsLinkBack = document.getElementById("contributionsLinkBack");
let contributionsLinksDiv = document.getElementById("contributionsLinks");

let manageOrdersIdLink = document.getElementById("manageOrdersId");
let manageOrdersLinkBack = document.getElementById("manageOrdersLinkBack");
let manageOrdersLinksDiv = document.getElementById("manageOrdersLinks");

let trusteeAccountIdLink = document.getElementById("trusteeAccountId");
let trusteeAccountLinkBack = document.getElementById("trusteeAccountLinkBack");
let trusteeAccountLinksDiv = document.getElementById("trusteeAccountLinks");

// --- Employer Submenue ---

let employeesIdLink = document.getElementById("employeesId");
let employeesLinkBack = document.getElementById("employeesLinkBack");
let employeesLinksDiv = document.getElementById("employeesLinks");

let employerContributionsIdLink = document.getElementById("employerContributionsId");
let employerContributionsLinkBack = document.getElementById("employerContributionsLinkBack");
let employerContributionsLinksDiv = document.getElementById("employerContributionsLinks");

let employerHelpIdLink = document.getElementById("employerHelpId");
let employerHelpLinkBack = document.getElementById("employerHelpLinkBack");
let employerHelpLinksDiv = document.getElementById("employerHelpLinks");

let employerAccountIdLink = document.getElementById("employerAccountId");
let employerAccountLinkBack = document.getElementById("employerAccountLinkBack");
let employerAccountLinksDiv = document.getElementById("employerAccountLinks");

let submenuLinksArray = [
    [HelpLink, HelpLinksDiv],
    [HelpLinkBack, HelpLinksDiv],
    [myAccountLinkBack, myAccountLinksDiv],
    [myAccountLink, myAccountLinksDiv],
    [myInvestmentLink, myInvestmentLinksDiv],
    [myInvestmentLinkBack, myInvestmentLinksDiv],
    
    // Trustee Submenue
    [companiesLinkBack, companiesLinksDiv],
    [companiesIdLink, companiesLinksDiv],

    [contributionsLinkBack, contributionsLinksDiv],
    [contributionsIdLink, contributionsLinksDiv],

    [manageOrdersLinkBack, manageOrdersLinksDiv],
    [manageOrdersIdLink, manageOrdersLinksDiv],

    [trusteeAccountLinkBack, trusteeAccountLinksDiv],
    [trusteeAccountIdLink, trusteeAccountLinksDiv],

    // Employer Submenue
    [employeesLinkBack, employeesLinksDiv],
    [employeesIdLink, employeesLinksDiv],

    [employerContributionsLinkBack, employerContributionsLinksDiv],
    [employerContributionsIdLink, employerContributionsLinksDiv],

    [employerHelpLinkBack, employerHelpLinksDiv],
    [employerHelpIdLink, employerHelpLinksDiv],

    [employerAccountLinkBack, employerAccountLinksDiv],
    [employerAccountIdLink, employerAccountLinksDiv],


]

submenuLinksArray.forEach(function(el) {
    if (el[0]) {
        el[0].addEventListener('click', function() {
            dropdownMenuMainDiv.classList.toggle('hide');
            el[1].classList.toggle('hide-all');
        })
    }
});

let employeeSubmenuDivsArray = [myAccountLinksDiv, HelpLinksDiv, myInvestmentLinksDiv]
let trusteeSubmenuDivsArray = [companiesLinksDiv, contributionsLinksDiv, manageOrdersLinksDiv, trusteeAccountLinksDiv]
let employerSubmenuDivsArray = [employeesLinksDiv, employerContributionsLinksDiv, employerHelpLinksDiv, employerAccountLinksDiv]

Array.from(dropdownMenuButton).forEach(function(el) {
    el.addEventListener('hide.bs.dropdown', function () {
        eqHeader.classList.toggle("bg-db");
        if (eqLogo) {
            eqLogo.classList.toggle("eq-db-blue-logo");
        }
        Array.from(hamburger).forEach(function (el) {
            el.classList.toggle("is-active");
        })
        bodyElement.classList.toggle('overflow-hide');
        if (dropdownMenuMainDiv && dropdownMenuMainDiv.classList.contains('hide')) {
            dropdownMenuMainDiv.classList.toggle('hide');
        }
        employeeSubmenuDivsArray.forEach(function(el) {
            if (el && !el.classList.contains('hide-all')) {
                el.classList.toggle('hide-all')
            }
        })

        trusteeSubmenuDivsArray.forEach(function(el) {
            if (el && !el.classList.contains('hide-all')) {
                el.classList.toggle('hide-all')
            }
        })

        employerSubmenuDivsArray.forEach(function(el) {
            if (el && !el.classList.contains('hide-all')) {
                el.classList.toggle('hide-all')
            }
        })
    })
    el.addEventListener('show.bs.dropdown', function () {
        eqHeader.classList.toggle("bg-db");
        Array.from(hamburger).forEach(function (el) {
            el.classList.toggle("is-active");
        })
        bodyElement.classList.toggle('overflow-hide');
    })
})

Array.from(dropdownMenu).forEach(function(element) {
    element.addEventListener('click', function(e) {
        e.stopPropagation();
    });
})